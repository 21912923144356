import fetch from "./xhr/index";

// /**
//  * 上传文件
//  * @param
//  */
// export const uploadUserXslx = param => fetch("upload", "/admin/uploadUserFile", param);

/**
 * 类目设置-删除类目
 * @param
 */
export const deleteCategory = (param) => fetch("POST", `/cms/cmsPlatformManage/delCategory`, param);

/**
 * 类目设置-新增/编辑类目
 * @param
 */
export const addEditcategory = (param) => fetch("POST", `/cms/cmsPlatformManage/updateCategory`, param);

/**
 * 类目设置-类目列表
 * @param
 */
export const categoryList = (param) => fetch("GET", `/cms/cmsPlatformManage/categoryList`, param);

/**
 * 类目设置-平台列表
 * @param
 */
export const platformList = (param) => fetch("GET", `/cms/cmsPlatformManage/plateList`, param);

/**
 * 增减人力-客服
 * @param
 */
export const deployService = (param) => fetch("GET", `/customerService/deploy/getServiceList`, param);

/**
 * 增减人力-提交
 * @param
 */
export const commitSubDetail = (param) => fetch("POST", `/customerService/deploy/addService`, param);

/**
 * 增减人力-删除附件
 * @param
 */
export const deployDelete = (param) => fetch("POST", `/customerService/deploy/fileDelete`, param);

/**
 * 增减人力-上传附件
 * @param
 */
export const deployUpload = (param) => fetch("POST", `/customerService/deploy/fileUpload`, param);

/**
 * 增减人力-查询店铺子账号
 * @param
 */
export const shopSubList = (param) => fetch("GET", `/customerService/deploy/getSubAccount`, param);
/**
 * 除客服外，所有人员下拉
 * @param
 */
export const adminList = (param) => fetch("GET", `/customerService/deploy/getAdminByParam`, param);

/**
 * 客服调配-增减人力(客服列表)
 * @param
 */
export const serviceList = (param) => fetch("GET", `/customerService/deploy/serviceDeploy`, param);

/**
 * 客服调配详情
 * @param
 */
export const serviceDeployDetail = (param) => fetch("GET", `/customerService/deploy/getShopDetail`, param);

/**
 * 客服调配列表
 * @param
 */
export const serviceDeployList = (param) => fetch("GET", `/customerService/deploy/getDeployList`, param);

/**
 * 店铺列表-子账号列表new
 * @param
 */
export const subaccountListNew = (param) => fetch("POST", `/cms/cmsSubaccountManage/subaccountList`, param);

/**
 * 店铺详情-添加工作记录
 * @param
 */
export const shopAddLog = (param) => fetch("POST", `/cms/cmsShop/addWorkLog`, param);

/**
 * 店铺详情-店铺客服列表
 * @param
 */
export const shopSerList = (param) => fetch("POST", `/cms/cmsShop/shopServiceList`, param);

/**
 * 店铺详情-店铺订单列表
 * @param
 */
export const shopOrderList = (param) => fetch("POST", `/cms/cmsShop/shopOrderList`, param);

/**
 * 店铺详情-删除交接文档
 * @param
 */
export const deleteShopFile = (param) => fetch("POST", `/cms/cmsShop/shopFileDelete`, param);

/**
 * 店铺详情-上传文档
 * @param
 */
export const uploadShopFile = (param) => fetch("POST", `/cms/cmsShop/shopFileUpload`, param);

/**
 * 店铺列表-店铺详情修改
 * @param
 */
export const editShopDetail = (param) => fetch("POST", `/cms/cmsShop/shopEditAdd`, param);

/**
 * 店铺列表-店铺详情
 * @param
 */
export const shopDetail = (param, routeParam) => fetch("GET", `/cms/cmsShop/detail/${routeParam}`, param);

/**
 * 客户公海-批量指派电销负责人
 * @param
 */
export const batchPhoneUser = param => fetch("POST", "/cms/cmsCustomer/batchPhoneUser", param);

/**
 * 历史开票信息
 * @param
 */
export const historyInvoice = param => fetch("GET", "/financial/financialFlow/getHistoryInvoice", param);

/**
 * 订单作废
 * @param
 */
export const setDeleteOrder = param => fetch("GET", "/cms/cmsCustomerOrder/setDelete", param);

/**
 * 续签失败
 * @param
 */
export const setFailOrder = param => fetch("GET", "/cms/cmsCustomerOrder/setFail", param);

/**
 * 上传客户公海
 * @param
 */
export const uploadSeaCus = param => fetch("upload", "/cms/cmsCustomer/insertBatchCustomer", param);

/**
 * 客户所属的店铺
 * @param
 */
export const cmsShopList = (param) => fetch("POST", `/cms/cmsShop/shopList`, param);

/**
 * 获取进账流水详情
 * @param
 */
export const flowDetail = (param) => fetch("GET", `/financial/financialFlow/getFlowDetail`, param);

/**
 * 订单进账流水作废
 * @param
 */
export const voidFlow = (param) => fetch("GET", `/financial/financialFlow/deleteFlow`, param);

/**
 * 订单id获取流水列表
 * @param
 */
export const orderFlowList = (param) => fetch("GET", `/financial/financialFlow/getFlowList`, param);

/**
 * 发起收款
 * @param
 */
export const incomeCommit = (param) => fetch("POST", `/financial/financialFlow/collection`, param);

/**
 * 收款明细接口
 * @param
 */
export const incomeDetail = (param) => fetch("GET", `/financial/financialFlow/getCollectionInfo`, param);

/**
 * 订单店铺列表-下拉/表格
 * @param
 */
export const orderShopList = (param) => fetch("GET", `/cms/cmsCustomerOrder/orderShops`, param);

/**
 * 删除订单合同
 * @param
 */
export const deleteOrderFile = (param) => fetch("POST", `/cms/cmsCustomerOrder/deleteFiles`, param);

/**
 * 上传订单合同
 * @param
 */
export const uploadOrderFile = (param) => fetch("POST", `/cms/cmsCustomerOrder/uploadFiles`, param);

/**
 * 同一个客户新增订单
 * @param
 */
export const cusNewOrder = (param) => fetch("POST", `/cms/cmsCustomerOrder/completeOne`, param);

/**
 * 手动调价提交
 * @param
 */
export const orderPriceEdit = (param) => fetch("POST", `/cms/cmsCustomerOrder/change`, param);

/**
 * 手动调价通知人下拉
 * @param
 */
export const orderNoticeList = (param) => fetch("POST", `/cms/cmsCustomerOrder/noticeList`, param);

/**
 * 计划提醒列表
 * @param
 */
export const planTaskList = (param) => fetch("POST", `/cms/cmsPlanTask/listPlanTask`, param);

/**
 * 公海客户详情
 * @param
 */
export const seaDetail = (param) => fetch("GET", `/cms/cmsCustomer/get`, param);

/**
 * 订单详情编辑
 * @param
 */
export const editOrderDetail = (param) => fetch("POST", `/cms/cmsCustomerOrder/add`, param);

/**
 * 机会详情编辑
 * @param
 */
export const editChanceDetail = (param) => fetch("POST", `/cms/cmsChance/add`, param);

/**
 * 公海客户编辑
 * @param
 */
export const editSeaDetail = (param) => fetch("POST", `/cms/cmsCustomer/add`, param);

/**
 * 下次跟进计划记录
 * @param
 */
export const nextPlanLst = (param, routeParam) => fetch("GET", `/cms/cmsFollowPlan/list/${routeParam}`, param);

/**
 * 下次跟进计划记录
 * @param
 */
export const chanceNextPlanList = (param) => fetch("POST", `/cms/cmsFollowPlan/list`, param);

/**
 * 机会下次跟进计划-- 新增
 * @param
 */
 export const ChanceNextPlan = (param) => fetch("POST", `/cms/cmsChance/createPlan`, param);

/**
 * 新增下次跟进计划
 * @param
 */
export const addNextPlan = (param) => fetch("POST", `/cms/cmsCustomer/createPlan`, param);

/**
 * 续签成功一键更新
 * @param
 */
export const synchronizeTime = (param, routeParam) => fetch("GET", `/cms/cmsCustomerOrder/synchronizeTime/${routeParam}`, param);

/**
 * 续签成功服务时间
 * @param
 */
export const orderShopServiceTime = (param, routeParam) => fetch("GET", `/cms/cmsCustomerOrder/orderShopServiceTime/${routeParam}`, param);

/**
 * 续签客户订单批量指派
 * @param
 */
export const assignRenewal = param => fetch("GET", "/cms/cmsCustomerOrder/assignRenewal", param);

/**
 * 续签客户定单列表
 * @param
 */
export const getRenewalList = param => fetch("GET", "/cms/cmsCustomerOrder/getRenewalList", param);

/**
 * 上传分配客服文件
 * @param
 */
export const serviceUpload = param => fetch("upload", "/excel/serviceUpload", param);

/**
 * 分公司子账号分配模板下载
 * @param
 */
export const serviceSubExport = (param) => fetch("GET", `/excel/serviceSubExport`, param);

/**
 * 店铺--操作日志
 * @param
 */
export const shopLog = (param) => fetch("POST", `/sys/sysUpdateLog/detail`, param);

/**
 * 分公司店铺--子账号--删除客服分配情况
 * @param
 */
export const deleteDistribution = (param, routeParam) => fetch("GET", `/cms/cmsSubaccountManage/distServiceDel/${routeParam}`, param);

/**
 * 分公司店铺--子账号--客服分配情况
 * @param
 */
export const distributionlist = (param) => fetch("POST", `/cms/cmsSubaccountManage/distributionlist`, param);

/**
 * 分公司子账号列表
 * @param
 */
export const getComSubList = (param) => fetch("POST", `/cms/cmsSubaccountManage/getComList`, param);

/**
 * 分公司店铺列表
 * @param
 */
export const getComList = (param) => fetch("POST", `/cms/cmsShop/getComList`, param);

/**
 * 分公司店铺列表--指派给客服--新增编辑
 * @param
 */
export const assignService = (param) => fetch("POST", `/cms/cmsSubaccountManage/distServiceEdit`, param);

/**
 * 总店铺列表--指派给分公司
 * @param
 */
export const assignSubaccount = (param) => fetch("POST", `/cms/cmsSubaccountManage/distribution`, param);

/**
 * 客户订单--编辑客户订单
 * @param
 */
export const editOrder = (param) => fetch("POST", `/cms/cmsCustomerOrder/update`, param);

/**
 * 客户订单--删除
 * @param
 */
export const deleteOrder = (param, routeParam) => fetch("GET", `/cms/cmsCustomerOrder/deleteCustomerOrder/${routeParam}`, param);

/**
 * 店铺订单--
 * @param
 */
export const shopOrder = (param) => fetch("POST", `/cms/cmsCustomerOrder/listByCompanyId`, param);

/**
 * 客户订单--
 * @param
 */
export const orderAppoint = (param) => fetch("POST", `/cms/cmsCustomerOrder/appoint`, param);

/**
 * 客户订单--销售续费
 * @param
 */
export const saleAndRecharge = (param) => fetch("POST", `/cms/cmsCustomerOrder/saleAndRecharge`, param);

/**
 * 客户订单--进入下一阶段
 * @param
 */
export const orderNext = (param) => fetch("GET", `/cms/cmsCustomerOrder/stepInto`, param);

/**
 * 客户订单--修改订单状态
 * @param
 */
export const updateOrder = (param, routeParam) => fetch("GET", `/cms/cmsCustomerOrder/update/${routeParam}`, param);

/**
 * 客户订单--订单反馈
 * @param
 */
export const orderFeedback = (param) => fetch("POST", `/cms/cmsCustomerOrderFeedback/create`, param);

/**
 * 客户订单--订单详情
 * @param
 */
export const getOrderDetail = (param) => fetch("GET", `/cms/cmsCustomerOrder/get`, param);

/**
 * 销售机会--成单
 * @param
 */
export const newOrder = (param, routeParam) => fetch("POST", `/cms/cmsChance/complete/${routeParam}`, param);

/**
 * 销售机会--进入下一阶段
 * @param
 */
export const chanceNextStep = (param) => fetch("GET", `/cms/cmsChance/stepInto`, param);

/**
 * 销售机会--订单流失
 * @param
 */
export const chanceLoss = (param, routeParam) => fetch("POST", `/cms/cmsChance/loss/${routeParam}`, param);

/**
 * 销售机会--添加反馈
 * @param
 */
export const addFeedback = (param) => fetch("POST", `/cms/cmsFeedback/create`, param);

/**
 * 销售机会--添加更新状态
 * @param
 */
export const updateChance = (param, routeParam) => fetch("POST", `/cms/cmsChance/update/${routeParam}`, param);

/**
 * 客户订单--添加工作日志
 * @param
 */
export const addOrderFollow = (param) => fetch("POST", `/cms/cmsCustomerOrder/addWorkLog`, param);

/**
 * 销售机会--添加跟进记录/跟进状态
 * @param
 */
export const addChanceFollow = (param) => fetch("POST", `/cms/cmsChance/publish`, param);

/**
 * 客户公海--添加跟进记录/跟进状态
 * @param
 */
export const addFollow = (param) => fetch("POST", `/cms/cmsCustomer/publish`, param);

/**
 * 客户公海--删除公海
 * @param
 */
export const deleteSea = (param, routeParam) => fetch("POST", `/cms/cmsCustomer/delete/${routeParam}`, param);

/**
 * 编辑销售机会
 * @param
 */
export const editChance = (param, routeParam) => fetch("POST", `/cms/cmsChance/updateCmsChance/${routeParam}`, param);

/**
 * 编辑客户信息
 * @param
 */
export const editCustomer = (param, routeParam) => fetch("POST", `/cms/cmsCustomer/update/${routeParam}`, param);

/**
 * 客户订单列表
 * @param
 */
export const custOrderList = (param) => fetch("POST", `/cms/cmsCustomerOrder/list`, param);

/**
 * 删除销售机会
 * @param
 */
export const deleteChance = (param, routeParam) => fetch("POST", `/cms/cmsChance/delete/${routeParam}`, param);

/**
 * 机会查看详情
 * @param
 */
export const chanceDetail = (param) => fetch("POST", `/cms/cmsChance/get`, param);

/**
 * 机会列表
 * @param
 */
export const getChanceList = (param) => fetch("POST", `/cms/cmsChance/list`, param);

/**
 * 捞取客户
 * @param
 */
export const custFishing = (param) => fetch("POST", `/cms/cmsCustomer/fishing`, param);

/**
 * 添加客户
 * @param
 */
export const addCustomer = (param) => fetch("POST", `/cms/cmsCustomer/create`, param);

/**
 * 客户详情
 * @param
 */
export const getCustDeatil = (param, routeParam) => fetch("GET", `/cms/cmsCustomer/get/${routeParam}`, param);

/**
 * 客户公海列表
 * @param
 */
export const custSeaList = (param) => fetch("POST", `/cms/cmsCustomer/getSeaCustomerList`, param);

/**
 * 子账号删除
 * @param
 */
export const deleteSubaccount = (param, routeParam) => fetch("GET", `/cms/cmsSubaccountManage/delete/${routeParam}`, param);

/**
 * 子账号新增修改
 * @param
 */
export const addEditSubaccount = (param) => fetch("POST", `/cms/cmsSubaccountManage/edit`, param);

/**
 * 子账号列表old
 * @param
 */
export const subaccountList = (param) => fetch("POST", `/cms/cmsSubaccountManage/list`, param);

/**
 * 删除店铺
 * @param
 */
export const deleteCmsShop = (param, routeParam) => fetch("POST", `/cms/cmsShop/delete/${routeParam}`, param);

/**
 * 获取已成交客户
 * @param
 */
export const getFinishedList = param => fetch("POST", "/cms/cmsCustomer/getFinishedList", param);

/**
 * 获取店铺服务数据
 * @param
 */
export const getShopList = param => fetch("POST", "/cms/cmsShop/list", param);

/**
 * 获取店铺服务数据
 * @param
 */
export const addEditShop = param => fetch("POST", "/cms/cmsShop/create", param);


// /**
//  * 获取指定用户信息
//  * @param
//  */
// export const getUserInfo = (param, routeParam) => fetch("GET", `/admin/userinfo/${routeParam}`, param);

/**
 * 获取平台类目列表
 * @param
 */
export const getPlatList = param => fetch("GET", "/cms/cmsPlatformManage/list", param);

/**
 * 新建平台类目
 * @param
 */
export const createPlat = param => fetch("POST", "/cms/cmsPlatformManage/createPlat", param);

/**
 * 编辑平台类目
 * @param
 */
export const updatePlat = param => fetch("POST", "/cms/cmsPlatformManage/updatePlat", param);

/**
 * 删除平台类目
 * @param
 */
export const delPlat = (param, routeParam) => fetch("GET", `/cms/cmsPlatformManage/deletePlat/${routeParam}`, param);

/**
 * 获取平台类目列表
 * @param
 */
export const poolHead = param => fetch("GET", "/cms/cmsCustomer/getReportList", param);

/**
 * 获取客户公海 客户数量详情的列表
 * @param
 */
export const seaHead = param => fetch("GET", "/cms/cmsCustomer/getSeaReportList", param);

/**
 * 销售机会 销售机会数量详情的列表
 * @param
 */
export const salesHead = param => fetch("GET", "/cms/cmsChance/getReportList", param);

/**
 * 店铺列表 新增店铺数量详情的列表
 * @param
 */
export const customerHead = param => fetch("GET", "/cms/cmsShop/getReportList", param);

/**
 * 项目设置列表
 * @param
 */
 export const projectList = param => fetch("POST", "/cms/cmsCustomerProject/list", param);

 /**
  * 项目设置列表-排序
  * @param
  */
 export const projectSort = param => fetch("GET", "/cms/cmsCustomerProject/sort", param);
 
 /**
  * 项目设置列表-编辑
  * @param
  */
export const projectEdit = param => fetch("GET", "/cms/cmsCustomerProject/updateById", param);
 
/**
 * 店铺服务均值默认数据
 * @param
 */
export const defaultData = param => fetch("GET", "/cms/cmsShopAssessment/defaultData", param);

/**
 * 店铺新增考核服务均值
 * @param
 */
export const addData = param => fetch("GET", "/cms/cmsShopAssessment/addData", param);

/**
 * 店铺取消考核服务均值
 * @param
 */
export const shopDelete = param => fetch("GET", "/cms/cmsShopAssessment/delete", param);

/**
 * 对应店铺服务均值列表
 * @param
 */
export const list = param => fetch("GET", "/cms/cmsShopAssessment/list", param);

/**
 * 订单中心 新增订单数 头部数据
 * @param
 */
export const selectOrderHeadNum = param => fetch("GET", "/cms/cmsCustomerOrder/selectOrderHeadNum", param);

/**
 * 订单中心 头部数据弹窗
 * @param
 */
export const getOrderReportList = param => fetch("GET", "/cms/cmsCustomerOrder/getOrderReportList", param);

/**
 * 客户中心 头部数据
 * @param
 */
export const customerTopInfo = param => fetch("GET", "/cms/cmsCustomer/getCustomerHeadNum", param);

/**
 * 客户中心 头部弹窗数据
 * @param
 */

export const customerTopList = param => fetch("GET", "/cms/cmsCustomer/getCustomerReportList", param);

/**
 * 销售四海列表
 * @param
 */
export const getMySeaCustomerList = param => fetch("POST", "/cms/cmsCustomer/getMySeaCustomerList", param);

/**
 * 线索公海 批量指派客户负责人
 * @param
 */
export const batchChargeUser = param => fetch("POST", "/cms/cmsCustomer/batchChargeUser", param);

/**
 * 线索私海头部数据
 * @param
 */
export const mySeaHeadNum = param => fetch("GET", "/cms/cmsCustomer/getMySeaHeadNum", param);

/**
 * 线索私海 批量打回公海
 * @param
 */
export const repulseToSea = param => fetch("POST", "/cms/cmsCustomer/batchRepulseToSea", param);

/**
 * 获取打回公海设置天数
 * @param
 */
export const highSeaScore = param => fetch("GET", "/cms/cmsCustomer/getHighSeaScore", param);

/**
 * 设置打回公海设置天数
 * @param
 */
export const setHighSeaScore = param => fetch("GET", "/cms/cmsCustomer/setHighSeaScore", param);

/*
* 客户回收站列表
* @param
*/
export const recoveryList = param => fetch("POST", "/cms/cmsCustomer/getRecycleBin", param);

/**
 * 客户-删除/恢复
 * @param
 */
export const recoveryCustomer = param => fetch("POST", "/cms/cmsCustomer/recycle", param);

/**
 * 客户回收站-头部数据弹窗列表
 * @param
 */
export const recoveryHead = param => fetch("GET", "/cms/cmsCustomer/getCustomerReportList", param);

/**
 * 店铺调价 - 提交
 * @param
 */
 export const chaneShopPrice = param => fetch("POST", "/cms/cmsCustomerOrder/chaneShopPrice", param);

/**
 * 品牌方账号列表
 * @param
 */
export const brandAccountList = param => fetch("GET", "/admin/brandAccountList", param);

/** 
 * 订单详情-订单调价列表
 * @param
 */
export const orderChangePrice = param => fetch("GET", "/orderTask/task/getChangePriceList", param);
